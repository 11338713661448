import { render, staticRenderFns } from "./isoDocumentDetail.vue?vue&type=template&id=79b4a0c2"
import script from "./isoDocumentDetail.vue?vue&type=script&lang=js"
export * from "./isoDocumentDetail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\NewDevelopment\\PROJECT\\KNCO\\FrontEnd\\KNCOFrontEnd\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('79b4a0c2')) {
      api.createRecord('79b4a0c2', component.options)
    } else {
      api.reload('79b4a0c2', component.options)
    }
    module.hot.accept("./isoDocumentDetail.vue?vue&type=template&id=79b4a0c2", function () {
      api.rerender('79b4a0c2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/sai/iso/isoDocumentDetail.vue"
export default component.exports